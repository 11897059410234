import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTriajes(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/triajes', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTriaje(ctx, { id }) {
      return new Promise((resolve, reject) => {
        // console.log(id)
        axios
          .get(`/triajes/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    buscarUsuarioId(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/buscar-usuario_id/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTriaje(ctx, triajeData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/triajes', triajeData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTriaje(ctx, triajeData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/triajes/${triajeData.id}`, triajeData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addServicioTriaje(ctx, triajeData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/triajes/${triajeData.id}/agregar-servicios`, triajeData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTriaje(ctx, triajeData) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/triajes/${triajeData.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteServicio(ctx, servicioSolicitado) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`triajes/servicios/${servicioSolicitado.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Historia Clinica
    addHistoriaClinica(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.post('/historia-clinica', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateHistoriaClinica(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/historia-clinica/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Tipos de servicios para salud
    fetchServiciosSalud() {
      return new Promise((resolve, reject) => {
        axios.get('/salud/tipos/servicios')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Derivados
    addDerivado(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/salud/derivados', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchServiciosOcade() {
      return new Promise((resolve, reject) => {
        axios.get('/salud/derivados/tipos/servicios')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
