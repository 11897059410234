<template>
  <div>
    <ModalFormularioVistaLectura
      :id-formulario="3"
      :id-usuario="paciente.id"
    />
    <b-alert
      v-if="showCard"
      :show="triajeData !== undefined"
    >
      <div class="alert-body">
        <b-row
          align-h="around"
        >
          <b-col
            lg="6"
            md="6"
            sm="12"
            class="text-center mb-50"
          >
            <b-button
              variant="warning"
              size="sm"
            >
              Consentimiento informado. 2021
              <b-badge variant="success">
                Completado
              </b-badge>
            </b-button>

          </b-col>
          <b-col
            lg="6"
            md="6"
            sm="12"
            class="text-center"
          >
            <b-button
              variant="warning"
              size="sm"
              @click="$emit('abrirModalLecturaFichaClinica')"
            >
              Ficha médica y/o clinica. 2021
              <b-badge variant="danger">
                Pendiente
              </b-badge>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="triajeData === undefined"
    >
      <h4 class="alert-heading">
        {{ mensajeError }} Error al obtener los datos del triaje
      </h4>
      <div class="alert-body">
        No se encontró ningun dato con ese identificador. Ir a
        <b-link
          class="alert-link"
          :to="{ name: 'salud-triajes-list'}"
        >
          Triajes lista
        </b-link>
        para otros triajes.
      </div>
    </b-alert>

    <historia-clinica-modal
      :paciente="paciente"
      :vamos-crear="vamosCrear"
      @update-numero_historia_clinica="(id, h) => setHistoriaClinica(id, h)"
    />

    <template v-if="showCard">
      <b-form @submit.prevent="onSubmit">

        <b-row>
          <!-- Card Datos Personales -->
          <b-col
            sm="12"
            md="6"
            lg="6"
          >
            <b-card
              no-body
              border-variant="primary"
            >

              <div class="m-2">
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <feather-icon
                        icon="UserIcon"
                        size="19"
                      />
                      <h4 class="mb-0 ml-50">
                        Datos personales
                      </h4>
                    </div>
                  </b-col>
                </b-row>
                <b-col
                  cols="12"
                  xl="12"
                >
                  <table class="mt-2 mt-xl-0 w-100 ">
                    <tr>
                      <th class="pb-50">
                        <feather-icon
                          icon="CheckIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">N° de historia clinica</span>
                      </th>
                      <td class="pb-50 text-capitalize">
                        <span>{{ paciente.historia_clinica ? paciente.historia_clinica : ' SIN HISTORIA CLINICA' }}</span>
                        <b-button
                          v-if="paciente.historia_clinica"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-modal.modal_historia_clinica
                          variant="relief-warning"
                          class="btn-icon"
                          size="sm"
                          @click="abrirModal"
                        >
                          <feather-icon
                            icon="EditIcon"
                            class="mr-50"
                            size="12"
                          />
                          <span class="align-middle">Editar</span>
                        </b-button>
                        <b-button
                          v-else
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-modal.modal_historia_clinica
                          variant="relief-primary"
                          class="btn-icon"
                          size="sm"
                          @click="abrirModal"
                        >
                          <feather-icon
                            icon="SaveIcon"
                            class="mr-50"
                            size="12"
                          />
                          <span class="align-middle">Agregar</span>
                        </b-button>

                      </td>
                    </tr>

                    <tr>
                      <th class="pb-50">
                        <feather-icon
                          icon="UserIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">N° Documento de identidad</span>
                      </th>
                      <td class="pb-50">
                        {{ paciente.numero_documento }}
                      </td>
                    </tr>

                    <tr>
                      <th class="pb-50">
                        <feather-icon
                          icon="StarIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Apellido paterno</span>
                      </th>
                      <td class="pb-50 text-capitalize">
                        {{ paciente.apellido_paterno }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <feather-icon
                          icon="FlagIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Apellido materno</span>
                      </th>
                      <td class="pb-50">
                        {{ paciente.apellido_materno }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <feather-icon
                          icon="VoicemailIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Nombres</span>
                      </th>
                      <td class="pb-50">
                        {{ paciente.nombres }}
                      </td>
                    </tr>

                    <tr>
                      <th class="pb-50">
                        <feather-icon
                          icon="HeartIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Fecha Nacimiento</span>
                      </th>
                      <td class="pb-50">
                        {{ paciente.fecha_nacimiento }}
                      </td>
                    </tr>

                    <tr>
                      <th class="pb-50">
                        <feather-icon
                          icon="HeartIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Edad</span>
                      </th>
                      <td class="pb-50">
                        {{ paciente.edad }}
                      </td>
                    </tr>

                    <tr>
                      <th class="pb-50">
                        <feather-icon
                          icon="FeatherIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Sexo</span>
                      </th>
                      <td class="pb-50">
                        {{ paciente.sexo_nombre }}
                      </td>
                    </tr>

                    <tr>
                      <th class="pb-50">
                        <feather-icon
                          icon="HomeIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Etnia</span>
                      </th>
                      <td class="pb-50">
                        {{ paciente.etnia }}
                      </td>
                    </tr>

                    <tr>
                      <th class="pb-50">
                        <feather-icon
                          icon="GlobeIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Dirección</span>
                      </th>
                      <td class="pb-50">
                        {{ paciente.direccion }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <feather-icon
                          icon="PhoneIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Celular</span>
                      </th>
                      <td class="pb-50">
                        {{ paciente.celular }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <feather-icon
                          icon="HomeIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Escuela</span>
                      </th>
                      <td class="pb-50">
                        {{ paciente.escuela }}
                      </td>
                    </tr>

                  </table>
                </b-col>
              </div>
            </b-card>
          </b-col>

          <!-- Datos antropométricos -->
          <b-col
            sm="12"
            md="6"
            lg="6"
          >
            <b-card
              no-body
              border-variant="primary"
            >
              <div class="m-2">
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <feather-icon
                        icon="ThermometerIcon"
                        size="19"
                      />
                      <h4 class="mb-0 ml-50">
                        Datos antropométricos
                      </h4>
                    </div>
                  </b-col>
                </b-row>
                <!-- Inputs  -->
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Temperatura"
                      label-cols-xl="6"
                      label-cols-md="6"
                      label-cols-xs="6"
                    >
                      <b-input-group size="sm">
                        <template #append>
                          <b-input-group-text class="bg-gradient-primary">
                            <strong>°C</strong>
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="triajeData.temperatura"
                          type="number"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Presión arterial(PA): "
                      label-cols-xl="6"
                      label-cols-md="6"
                    >
                      <b-input-group size="sm">
                        <template #append>
                          <b-input-group-text class="bg-gradient-primary">
                            <strong>x mmHg</strong>
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="triajeData.presion_arterial"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Saturación (SAT):"
                      label-cols-xl="6"
                      label-cols-md="6"
                    >
                      <b-input-group size="sm">
                        <template #append>
                          <b-input-group-text class="bg-gradient-primary">
                            <strong>%</strong>
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="triajeData.saturacion"
                          type="number"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Frecuencia cardiaca (FC):"
                      label-cols-xl="6"
                      label-cols-md="6"
                    >
                      <b-input-group size="sm">
                        <template #append>
                          <b-input-group-text class="bg-gradient-primary">
                            <strong>x min</strong>
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="triajeData.frecuencia_cardiaca"
                          type="number"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Frecuencia respiratoria (FR):"
                      label-cols-xl="6"
                      label-cols-md="6"
                    >
                      <b-input-group size="sm">
                        <template #append>
                          <b-input-group-text class="bg-gradient-primary">
                            <strong>x min</strong>
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="triajeData.frecuencia_respitatoria"
                          type="number"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Peso(*):"
                      label-cols-xl="6"
                      label-cols-md="6"
                    >
                      <b-input-group size="sm">
                        <template #append>
                          <b-input-group-text class="bg-gradient-primary">
                            <strong>Kg.</strong>
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="triajeData.peso"
                          type="number"
                          @input="calcularICM"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Talla(*):"
                      label-cols-xl="6"
                      label-cols-md="6"
                    >
                      <b-input-group size="sm">
                        <template #append>
                          <b-input-group-text class="bg-gradient-primary">
                            <strong>cm.</strong>
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="triajeData.talla"
                          type="number"
                          @input="calcularICM"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Perimetro abdominal (PAB):"
                      label-cols-xl="6"
                      label-cols-md="6"
                    >
                      <b-input-group size="sm">
                        <template #append>
                          <b-input-group-text class="bg-gradient-primary">
                            <strong>cm.</strong>
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="triajeData.perimetro_abdominal"
                          type="number"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>

              </div>
            </b-card>
          </b-col>

          <!-- Card Resultado -->
          <b-col
            sm="12"
            md="6"
            lg="6"
          >
            <b-card
              border-variant="primary"
              no-body
            >
              <div class="m-2">
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <feather-icon
                        icon="UserIcon"
                        size="19"
                      />
                      <h4 class="mb-0 ml-50">
                        Resultado
                      </h4>
                    </div>
                  </b-col>
                </b-row>

                <b-table-simple
                  class="mt-1"
                  bordered
                  stacked="sm"
                >
                  <b-thead>
                    <b-tr>
                      <b-th variant="danger">
                        Descripción
                      </b-th>
                      <b-th variant="danger">
                        Resultado
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-td>Superficie corporal (SC):</b-td>
                      <b-td>
                        <i>
                          <strong>
                            {{ triajeData.superficie_corporal }} m<sup>2</sup>
                          </strong>
                        </i>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>Índice de masa corporal (Valor IMC):</b-td>
                      <b-td>
                        <i>
                          <strong>
                            {{ triajeData.icm }} Kg/m<sup>2</sup>
                          </strong>
                        </i>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>

                <!-- Tabla de valoracion ICM -->
                <b-row class="mt-1">
                  <b-col
                    class="text-capitalize"
                  >
                    <b-table-simple
                      stacked="sm"
                      bordered
                    >
                      <b-thead>
                        <b-tr>
                          <b-th stacked-heading="Estado">
                            Estado
                          </b-th>
                          <b-th stacked-heading="Clasificación">
                            Clasificación
                          </b-th>
                          <b-th stacked-heading="Valor ICM">
                            Valor ICM
                          </b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          v-if="triajeData.icm <= 18.5"
                          variant="info"
                        >
                          <b-td
                            stacked-heading="Estado"
                            class="text-center text-danger"
                          >
                            <feather-icon

                              size="32"
                              icon="ArrowRightCircleIcon"
                            />
                          </b-td>
                          <b-td stacked-heading="Clasificación">
                            bajo peso
                          </b-td>
                          <b-td stacked-heading="Valor ICM">
                            &#60; 18.5
                          </b-td>
                        </b-tr>
                        <b-tr
                          v-if="triajeData.icm >= 18.5 && triajeData.icm <= 24.9"
                          variant="success"
                        >
                          <b-td
                            stacked-heading="Estado"
                            class="text-center text-danger border-0"
                          >
                            <feather-icon

                              size="32"
                              icon="ArrowRightCircleIcon"
                            />
                          </b-td>
                          <b-td stacked-heading="Clasificación">
                            peso normal
                          </b-td>
                          <b-td stacked-heading="Valor ICM">
                            18.5 - 24.9
                          </b-td>
                        </b-tr>
                        <b-tr
                          v-if="triajeData.icm >= 25 && triajeData.icm <= 29.9"
                          class="bg-warning bg-lighten-5"
                        >
                          <b-td
                            stacked-heading="Estado"
                            class="text-center text-danger"
                          >
                            <feather-icon

                              size="32"
                              icon="ArrowRightCircleIcon"
                            />
                          </b-td>
                          <b-td stacked-heading="Clasificación">
                            sobre peso
                          </b-td>
                          <b-td stacked-heading="Valor ICM">
                            25 - 29.9
                          </b-td>
                        </b-tr>
                        <b-tr
                          v-if="triajeData.icm >= 30 && triajeData.icm <= 34.9"
                          class="bg-warning bg-lighten-3 text-white"
                        >
                          <b-td
                            stacked-heading="Estado"
                            class="text-center text-danger"
                          >
                            <feather-icon

                              size="32"
                              icon="ArrowRightCircleIcon"
                            />
                          </b-td>
                          <b-td stacked-heading="Clasificación">
                            obesidad tipo 1
                          </b-td>
                          <b-td stacked-heading="Valor ICM">
                            30 - 34.9
                          </b-td>
                        </b-tr>
                        <b-tr
                          v-if="triajeData.icm >= 35 && triajeData.icm <= 39.9"
                          class="bg-warning text-white"
                        >
                          <b-td
                            stacked-heading="Estado"
                            class="text-center text-danger"
                          >
                            <feather-icon

                              size="32"
                              icon="ArrowRightCircleIcon"
                            />
                          </b-td>
                          <b-td stacked-heading="Clasificación">
                            obesidad tipo 2
                          </b-td>
                          <b-td stacked-heading="Valor ICM">
                            35 - 39.9
                          </b-td>
                        </b-tr>
                        <b-tr
                          v-if="triajeData.icm >= 40"
                          class="bg-danger text-white"
                        >
                          <b-td
                            stacked-heading="Estado"
                            class="text-center"
                          >
                            <feather-icon
                              size="32"
                              icon="ArrowRightCircleIcon"
                            />
                          </b-td>
                          <b-td stacked-heading="Clasificación">
                            obesidad tipo 3
                          </b-td>
                          <b-td stacked-heading="Valor ICM">
                            &#62;&#61; 40
                          </b-td>
                        </b-tr>
                      </b-tbody>

                    </b-table-simple>
                  </b-col>
                </b-row>

              </div>
            </b-card>
          </b-col>

          <!-- Input Observaciones y boton guardar -->
          <b-col
            sm="12"
            md="6"
            lg="6"
          >
            <b-card
              border-variant="primary"
              no-body
            >
              <div class="m-2">

                <b-row>
                  <b-col cols="12">
                    <b-form-group>
                      <template #label>
                        <label class="col-form-label">Observaciones</label>
                        <small
                          class="text-danger"
                        >(*) Obligatorio</small>
                      </template>
                      <b-form-textarea
                        id="textarea"
                        v-model="triajeData.observaciones"
                        rows="7"
                        max-rows="7"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row
                  cols-md="8"
                  class="justify-contentd-flex justify-content-center"
                >
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-50 mb-sm-0 mr-0 mr-sm-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="submit"
                  >
                    Guardar cambios
                  </b-button>
                </b-row>
              </div>

            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </template>
  </div>
</template>

<script>
import {
  BAlert, BLink, BRow, BCol, BButton, BCard, BForm,
  BBadge,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BInputGroup,
  BInputGroupText,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTd,
  BTh,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ModalFormularioVistaLectura from '@/views/salud/triaje/triajes-list/ModalFormularioVistaLectura.vue'
import Ripple from 'vue-ripple-directive'
import triajeStoreModule from '../triajeStoreModule'
import HistoriaClinicaModal from './HistoriaClinicaModal.vue'

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BForm,
    BCard,
    BAlert,
    BButton,
    BBadge,
    BRow,
    BCol,
    BLink,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BInputGroup,
    BInputGroupText,
    HistoriaClinicaModal,
    FeatherIcon,
    ModalFormularioVistaLectura,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // required,
      edad: null,
      vamosCrear: true,
    }
  },
  methods: {
    abrirModal() {
      if (this.paciente.historia_clinica_id !== null) {
        this.vamosCrear = false
      } else {
        this.vamosCrear = true
      }
    },
    setHistoriaClinica(id, h) {
      this.paciente.historia_clinica_id = id
      this.paciente.historia_clinica = h
    },
    calcularICM() {
      const icm = this.triajeData.peso / ((this.triajeData.talla / 100) ** 2)
      this.triajeData.icm = Number.parseFloat(icm).toFixed(1)

      // eslint-disable-next-line no-restricted-properties
      const sc = ((Number(0.7184) * Math.pow(this.triajeData.talla, 0.725)) * Math.pow(this.triajeData.peso, 0.425)) / 100
      this.triajeData.superficie_corporal = sc.toFixed(2)
      // console.log('Superficie corporal', sc.toFixed(2))
    },
    onSubmit() {
      // console.log(this.triajeData)

      this.triajeDataForm = { ...this.triajeData }
      this.triajeDataForm.estado = 'completado'
      if (this.triajeData.id) {
      // Vamos a editar
        // console.log('Vamos a editar')
        store.dispatch('triajes/updateTriaje', this.triajeDataForm)
          .then(() => {
            this.toastSuccess('', 'top-right', '¡Actualización exitosa!')
            this.$router.push({ name: 'salud-triajes-list' })
          }).catch(error => {
            if (error.response.data.code === 422) {
              this.toastError('Rellenar los campos obligatorios')
            }
            if (error.response.data.code === 403) {
              this.toastError(`${error.response.data.error}`)
            }
            if (error.response.data.code === 409) {
              this.toastError(`${error.response.data.error}`)
            }
          })
      } else {
        // Vamos a registrar
        // console.log('Estamo aptos a registrar')
        store.dispatch('triajes/addTriaje', this.triajeDataForm)
          .then(() => {
            this.toastSuccess('Ya puedes agregar servicios')
            this.$router.push({ name: 'salud-triajes-list' })
          }).catch(error => {
            if (error.response.data.code === 422) {
              this.toastError('Rellenar los campos obligatorios')
            }
          })
      }
    },
  },
  setup() {
    const TRIAJES_APP_STORE_MODULE_NAME = 'triajes'

    // Register module
    if (!store.hasModule(TRIAJES_APP_STORE_MODULE_NAME)) store.registerModule(TRIAJES_APP_STORE_MODULE_NAME, triajeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TRIAJES_APP_STORE_MODULE_NAME)) store.unregisterModule(TRIAJES_APP_STORE_MODULE_NAME)
    })
    const showCard = ref(false)
    const paciente = ref({})

    const mensajeError = ref('')

    // const triajeData = ref(null)

    const blankTriajeData = {
      id: null,
      persona: {},
      paciente_id: null,
      servidor_public_id: null,
      cita_id: null,
      temperatura: null,
      presion_arterial: null,
      saturacion: null,
      frecuencia_cardiaca: null,
      frecuencia_respiratoria: null,
      peso: null,
      talla: null,
      icm: null,
      superficie_corporal: null,
      perimeto_abdominal: null,
      fecha_atencion: null,
      observaciones: null,
    }

    const triajeData = ref(JSON.parse(JSON.stringify(blankTriajeData)))

    const resetTriajeData = () => {
      triajeData.value = JSON.parse(JSON.stringify(blankTriajeData))
    }

    // Para obtener al paciente por su id usuarios
    if (router.currentRoute.params.persona) {
      // console.log('Persona', router.currentRoute.params.persona)
      store.dispatch('triajes/buscarUsuarioId', { id: router.currentRoute.params.persona })
        .then(response => {
          // console.log('Por usuario id', response.data)
          paciente.value = response.data
          // triajeData.value.persona = response.data
          triajeData.value.paciente_id = router.currentRoute.params.persona
          paciente.value.usuario_id = router.currentRoute.params.persona
          // paciente.value
          showCard.value = true
        })
    }

    // Obtener el triaje por su id
    if (router.currentRoute.params.id) {
      // console.log('Triaje', router.currentRoute.params.id)
      store.dispatch('triajes/fetchTriaje', { id: router.currentRoute.params.id })
        .then(response => {
          // console.log('triaje data', response.data)
          const { persona, ...rest } = response.data
          // const { persona } = usuario.perfil
          triajeData.value = rest
          paciente.value = persona
          paciente.value.usuario_id = rest.paciente_id
          showCard.value = true
        })
        .catch(error => {
          if (error.response.status === 404) {
            triajeData.value = undefined
          }
          if (error.response.status === 403) {
            triajeData.value = undefined
            mensajeError.value = error.response.data.error
          }
        })
    }

    return {
      triajeData,
      showCard,
      paciente,
      resetTriajeData,
      mensajeError,
    }
  },
}
</script>

<style scoped>
/* .table .thead-dark th {
  background-color: #e42121;
    border-color: #ffffff;
} */
</style>
<style lang="css" scoped>

  /* Number Type Input Box Scss for - Remove arrow on hover */
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: auto !important;
  }

</style>
